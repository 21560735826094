import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

let pageSendDocs = $('.envioDocumentos');
if(pageSendDocs.length > 0) {

    $('#btnAddMoreFileUpload').on('click', function(){
        let totalFiels = $('.fileUpload').length
        const dataId = $('.contentAddMoreFiles').data('id');
        const fieldId = dataId + totalFiels
        $('.contentAddMoreFiles').append(templateFileUpload(dataId, fieldId));

    });

}

function templateFileUpload(id, count) {
    let template = `
        <div class="input-group">
            <input type="file" name="documento_${id}[]" class="form-control fileUpload" id="doc-${count}" aria-label="Upload">
        </div>
    `;
    return template;
}