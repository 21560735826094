import $ from "jquery";
import { IsEmail } from "../formularios";

const formDuvida = $('#formDuvida');
if(formDuvida.length > 0) {

    $('#btnAddMoreDocUpload').on('click', function(){
        let totalFiels = $('.fileUploadDoc').length
        const dataId = $('.contentAddMoreFiles').data('id');
        const fieldId = dataId + totalFiels
        $('.contentAddMoreFiles').append(templateFileUploadDoc(fieldId));

    });
    
    $('#btnEnviarDuvida').on('click', function(e){
        e.preventDefault();
        let erros = 0;
        let nome = formDuvida.find('#nome');
        let email = formDuvida.find('#email');
        let relato = formDuvida.find('#relato');
        let response = grecaptcha.getResponse();

        if (nome.val() === "") {
            $('#nomeError').fadeIn();
            erros++;
        } else {
            $('#nomeError').fadeOut();
        }
        
        if (email.val() === "" || !IsEmail(email.val())) {
            $('#emailError').fadeIn();
            erros++;
        } else {
            $('#emailError').fadeOut();
        }

        if (relato.val() === "") {
            $('#relatoError').fadeIn();
            erros++;
        } else {
            $('#relatoError').fadeOut();
        }

        if(response.length == 0) {
            //reCaptcha not verified
            $('#recaptchaError').fadeIn();
            erros++;
        } else {
            //reCaptch verified
            $('#recaptchaError').fadeOut();
        }

        if(erros === 0) {
            formDuvida.submit();
        }

    });

    
}

function templateFileUploadDoc(count) {
    let template = `
        <div class="input-group mb-3">
            <input type="file" name="documentos[]" class="form-control fileUploadDoc" id="docForm-${count}" aria-label="Upload">
        </div>
    `;
    return template;
}