import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
import { IsEmail } from "./formularios";
import Cookies from 'js-cookie';

const verifique = $('.verifiqueSeuDireito');
if(verifique.length > 0) {

    let application = {};
    let perguntas = [];

    const tipoUrl = getUrlParameter('tipo');
    if (tipoUrl != '') {
        application.tipo = tipoUrl;
        $('#problema-'+tipoUrl).addClass('active');
        $('#stepTipo .problema').removeClass('active');
        $('#stepTipo').removeClass('active');
        $('#ciaaerea').addClass('active');
    }

    $('#stepTipo .problema').on('click', function(){
        application.tipo = $(this).data('tipo');
        $(this).addClass('active');
        $('#stepTipo .problema').removeClass('active');
        $('#stepTipo').removeClass('active');
        $('#ciaaerea').addClass('active');
    });
    
    $('#companhia').on('change', function(){
        let cia = $(this).val();
        application.cia = cia;
        $('#companhia').removeClass('active');
        getTodasPerguntas(application.tipo, application.cia, perguntas);
    });


    //Cliente não quer ajuda
    $('.btnSemAjuda').on('click', function(){
        $('.pergunta').removeClass('active');
        $('.perguntaSemAjuda').addClass('active');
    });
    
}

function getTodasPerguntas($tipo, $ciaArea, $perguntas) {
    $.get("/get_perguntas/"+$tipo, function( data ) {
        let totaldata = data.length;
        if(totaldata >= 1) {
            // console.log(data);
            $('#listaPerguntas').html('');
            for (var objKey in data) {
                $('#listaPerguntas').append(
                    templatePergunta(data[objKey].id, data[objKey].titulo, data[objKey].respostas)
                );
            }
            $('#listaPerguntas .pergunta').eq(0).addClass('active');
            clickProblema($perguntas, $tipo, $ciaArea);
        } else {
            //Prenche dados no form Düvida
            $('#tipo_problema_id').val($tipo);
            $('#cia_aerea_id').val($ciaArea);
            
            $('#listaPerguntas').html('');
            $('.stepPerguntas').removeClass('active');
            $('.stepFinal').addClass('active');
            $('.formDuvida').addClass('active');
        }
    });
}

function escapeHTML(html) {
    if (!html) {
        return '';
    }
    return html
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#039;');
}



function templatePergunta(id, titulo, respostas) {
    let template = `
        <div class="pergunta" id="${id}">
            <h2>${titulo}</h2>
            <ul class="listPerguntas">
    `;
    for (var objKey in respostas) {
        let escapedTexto = escapeHTML(respostas[objKey].msg_texto);
        template += `
            <li><a href="javascript:void(0);" class="problema" data-idpergunta="${respostas[objKey].pergunta_id}" data-proxima="${respostas[objKey].proxima_pergunta_id}" data-id="${respostas[objKey].id}" data-direito="${respostas[objKey].msg_direto}" data-erro="${respostas[objKey].msg_erro}"  data-form="${respostas[objKey].msg_form}" data-texto="${escapedTexto}">${respostas[objKey].titulo}</a></li>
        `;
    }
    template += `
            </ul>
        </div>
    `;
    return template;
}


function clickProblema(perguntas, tipo, ciaaerea){

    $('.problema').on('click', function(){
        const direito = $(this).data('direito');
        const erro = $(this).data('erro');
        const form = $(this).data('form');
        const texto = $(this).data('texto');
        const nextQuestion = $(this).data('proxima');

        $(this).addClass('active');
        $('.pergunta').removeClass('active');

        if(direito === 1) {
            $('.stepPerguntas').removeClass('active');
            $('.stepFinal').addClass('active');
            $('.perguntaDireito').addClass('active');
            
            $('.btnLoginPerguntas').on('click', function(){

                $('.verifiqueSeuDireito h1').fadeOut();

                // console.log('cliquelogin: ', perguntas);
                $('#perguntasRespostasLogin').val(JSON.stringify(perguntas));
                $('#perguntasRespostasCadastro').val(JSON.stringify(perguntas));
                
                $('#tipoLogin').val(tipo);
                $('#tipoCadastro').val(tipo);
                
                $('#ciaareaLogin').val(ciaaerea);
                $('#ciaareaCadastro').val(ciaaerea);

                $('.perguntaDireito').removeClass('active');

                let cadastro = parseInt($(this).data('cadastro'));
                if(cadastro > 0) {

                    const inputData = {};
                    inputData.perguntas = JSON.stringify(perguntas);
                    inputData.tipo_problema_id = tipo;
                    inputData.cia_aerea_id = ciaaerea;

                    $.ajax({
                        type: 'POST',
                        url: '/cadastro/criaFromPerguntas',
                        processData: false,
                        contentType: 'application/json; charset=utf-8',
                        data: JSON.stringify( inputData ),
                        headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                        success: function(dataReturn) {
                            // console.log(dataReturn);
                            if(dataReturn.erro === false) {
                                window.location.replace("/cadastro/cadastro-reclamacao");
                            }
                        },
                        error: function(dataReturn) {
                            console.log('Status: '+dataReturn.status);
                        }
                    });

                } else {
                    $('.formLoginPerguntas').addClass('active');

                    $('#btnEfetuaLoginPerguntas').on('click', function(e){
                        e.preventDefault();

                        const inputData = {};
                        inputData.perguntas = JSON.stringify(perguntas);
                        inputData.tipo_problema_id = tipo;
                        inputData.cia_aerea_id = ciaaerea;
                        inputData.email = '';
                        inputData.senha = '';

                        let erros = 0;
                        let email = $('#emailLoginPergunta');
                        let senha = $('#senhaLoginPergunta');

                        if (senha.val() === "") {
                            senha.addClass('errorField');
                            $('#senhaErrorLogin').fadeIn();
                            erros++;
                        } else {
                            senha.removeClass('errorField');
                            inputData.senha = senha.val();
                            $('#senhaErrorLogin').fadeOut();
                        }
                        
                        if (email.val() === "" || !IsEmail(email.val())) {
                            email.addClass('active');
                            $('#emailErrorLogin').fadeIn();
                            erros++;
                        } else {
                            email.removeClass('active');
                            inputData.email = email.val();
                            $('#emailErrorLogin').fadeOut();
                        }

                        if(erros === 0) {
                            $.ajax({
                                type: 'POST',
                                url: '/cadastro/criaCadastroFromLogin',
                                processData: false,
                                contentType: 'application/json; charset=utf-8',
                                data: JSON.stringify( inputData ),
                                headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                                success: function(dataReturn) {
                                    // console.log(dataReturn);
                                    if(dataReturn.erro === false) {
                                        $('.erroLoginPerguntas').fadeOut();
                                        window.location.replace("/cadastro/cadastro-reclamacao");
                                    } else {
                                        $('.erroLoginPerguntas').fadeIn();
                                    }
                                },
                                error: function(dataReturn) {
                                    console.log('Status: '+dataReturn.status);
                                }
                            });
                        }

                    });

                }
            });

        } else if (erro === 1) {

            $('#texto-erro').html('');
            $('#texto-erro').html(texto);

            $('.stepPerguntas').removeClass('active');
            $('.stepFinal').addClass('active');
            $('.perguntaErro').addClass('active');
        } else if(form === 1) {
            $('#texto-erro-form').html('');
            $('#texto-erro-form').html(texto);
            $('#perguntas_duvida').val(JSON.stringify(perguntas));
            $('#tipoDuvida').val(tipo);
            $('#ciaareaDuvida').val(ciaaerea);
            $('.stepPerguntas').removeClass('active');
            $('.stepFinal').addClass('active');
            $('.formDuvida').addClass('active');
            
        } else {
            // console.clear();
            let dataInfo = {};
            dataInfo.idPergunta = $(this).data('idpergunta');
            dataInfo.idresposta = $(this).data('id');
            perguntas.push(dataInfo);
            $('#'+nextQuestion).addClass('active');
        }


        // console.warn(perguntas);
        var data = {};
        data.perguntas = JSON.stringify(perguntas);
        console.log('pergutnas json: ', JSON.stringify(perguntas));
        console.log('pergutnas: ',data.perguntas);

    });

}

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
        }
    }
    return false;
};