window.$ = window.jQuery = require("jquery");
import 'bootstrap';

import "./_commom/isDisable.js";
// import { header } from "./componentes/header.js";
import { menu, menuMobile } from "./componentes/menu.js";
// import "./componentes/animacoes.js";
import "./componentes/vitrine.js";
import "./componentes/direitos.js";
import "./componentes/ajuda.js";
import "./componentes/verifique.js";

import "./componentes/formularios.js";
// import "./forms/contato.js";
import "./componentes/forms/cadastro.js";
import "./componentes/forms/duvida.js";
import "./componentes/forms/topicos.js";

import "./componentes/documentos.js";
import "./componentes/assinaturaContrato.js";

// window.$ = window.jQuery = jQuery;

window.addEventListener("load", function(event) {   
    // header();
    menu();
    menuMobile();
    // sliderModelos();
});
