import $ from "jquery";
import { validate } from 'gerador-validador-cpf';
import { IsEmail } from "../formularios";

const formCadastro = $('#formCadastro');
if(formCadastro.length > 0) {

    $('#email').on('focusout', function(){
        const emailUsuario = $(this).val();
        if(IsEmail(emailUsuario) === false) {
            $(this).addClass('errorField');
        } else {
            $(this).removeClass('errorField');

            const inputData = {};
            inputData.email = emailUsuario;

            $.ajax({
                type: 'POST',
                url: '/verifica_email',
                processData: false,
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify( inputData ),
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                success: function(dataReturn) {
                    // console.log(dataReturn);
                    if(dataReturn.erro === true) {
                        $('#email').addClass('errorField');
                        $('#emailError').html(dataReturn.msg);
                        $('#emailError').fadeIn();
                    } else {
                        $('#email').removeClass('errorField');
                        $('#emailError').fadeOut();
                    }
                },
                error: function(dataReturn) {
                    console.log('Status: '+dataReturn.status);
                }
            });

        }
    });

    $('#cpf').on('focusout', function(){
        const cpfUsuario = $(this).val();
        if(validate(cpfUsuario) === false) {
            $(this).addClass('errorField');
        } else {
            $(this).removeClass('errorField');
            
            const inputData = {};
            inputData.cpf = cpfUsuario;
            $.ajax({
                type: 'POST',
                url: '/verifica_cpf',
                processData: false,
                contentType: 'application/json; charset=utf-8',
                data: JSON.stringify( inputData ),
                headers: {'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')},
                success: function(dataReturn) {
                    // console.log(dataReturn);
                    if(dataReturn.erro === true) {
                        $('#cpf').addClass('errorField');
                        $('#cpfError').html(dataReturn.msg);
                        $('#cpfError').fadeIn();
                    } else {
                        $('#cpf').removeClass('errorField');
                        $('#cpfError').fadeOut();
                    }
                },
                error: function(dataReturn) {
                    console.log('Status: '+dataReturn.status);
                }
            });
        }
    });

}