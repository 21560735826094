import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

let direitos = $('.direitoDosPassageiros');
if(direitos.length > 0) {

    $('#direitos .card-header').on('click', function(){
        const idElement = $(this).data('show');
        // console.log(idElement);
        $('.collapseContent').removeClass('show');
        $('#'+idElement).addClass('show');
        let hash = '#'+idElement;
        $('html, body').animate({
            scrollTop: $(hash).offset().top - 230
        }, 100);
    });

}