import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

let assinaturaContrato = $('.assinaturaContrato');
const urlD4Sign = document.querySelector('meta[name="urlD4Sign"]').getAttribute('content');
if(assinaturaContrato.length > 0) {

    $('#btnOpenModalAssinaturaContato').on('click', function(){

        const titulo = $(this).data('titulo')
        const documento = $(this).data('documento')
        const email = $(this).data('email')
        const nome = $(this).data('nome')
        const dataNascimento = $(this).data('datanascimento')

        $('#tituloModal').html(titulo)

        assinaContrato(documento, email, nome, dataNascimento)
        $('.modalAssinatura').fadeIn()
        closeModal()

    });
    
    $('#btnOpenModalAssinaturaProcuracao').on('click', function(){

        const titulo = $(this).data('titulo')
        const documento = $(this).data('documento')
        const email = $(this).data('email')
        const nome = $(this).data('nome')
        // const dataNascimento = $(this).data('datanascimento')

        $('#tituloModal').html(titulo)

        assinaProcuracao(documento, email, nome)
        $('.modalAssinatura').fadeIn()
        closeModal()

    });

    function closeModal(){
        $('.closeModal').on('click', function(){
            $('.modalAssinatura').fadeOut()
            $('#tituloModal').html('')
            $('#signature-div').html('')
        })
    }

    function assinaContrato($idDocumento, $email, $nome) {

        var key                     = $idDocumento;
        var signer_disable_preview  = "0";
        var signer_email            = $email;
        var signer_display_name     = $nome; //Opcional
        var signer_documentation    = ""; //Opcional
        var signer_birthday         = ""; //Opcional
        var signer_key_signer       = ""; //Opcional
        
        var host            = urlD4Sign+"/embed/viewblob";
        var container       = "signature-div";    
        var width           = '100%';
        var height          =  '100%';
        //----------FIM DAS VARIAVEIS----------//
        
        var is_safari = navigator.userAgent.indexOf('Safari') > -1;
        var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
        if ((is_chrome) && (is_safari)) {is_safari = false;}  
        if (is_safari) {
            if (!document.cookie.match(/^(.*;)?\s*fixed\s*=\s*[^;]+(.*)?$/)) {
                document.cookie = 'fixed=fixed; expires=Tue, 19 Jan 2038 03:14:07 UTC; path=/';
                var url = document.URL;
                var str = window.location.search;
                var param = str.replace("?", "");
                if (url.indexOf("?")>-1){
                    url = url.substr(0,url.indexOf("?"));
                }
                window.location.replace("https://secure.d4sign.com.br/embed/safari_fix?param="+param+'&r='+url);
            }
        }
        let iframe = document.createElement("iframe");
        if (signer_key_signer === ''){
            iframe.setAttribute("src", host+'/'+key+'?email='+signer_email+'&display_name='+signer_display_name+'&documentation='+signer_documentation+'&birthday='+signer_birthday+'&disable_preview='+signer_disable_preview);
        }else{
            iframe.setAttribute("src", host+'/'+key+'?email='+signer_email+'&display_name='+signer_display_name+'&documentation='+signer_documentation+'&birthday='+signer_birthday+'&disable_preview='+signer_disable_preview+'&key_signer='+signer_key_signer);
        }
        iframe.setAttribute("id", 'd4signIframe');
        iframe.setAttribute("width", width);
        iframe.setAttribute("height", height);
    
        iframe.style.border = 0;
        iframe.setAttribute("allow", 'geolocation');
        var cont = document.getElementById(container);
        cont.appendChild(iframe);
        window.addEventListener("message", (event) => {
            if (event.data === "signed") {
                
                let totalAss = parseInt($('#countAssinaturas').val())
                totalAss = totalAss + 1
                $('#countAssinaturas').val(totalAss)

                $('#btnOpenModalAssinaturaContato').fadeOut()
                $('#contratoAssinadoAlert').fadeIn()
                $('.modalAssinatura').fadeOut()
                $('#tituloModal').html('')
                $('#signature-div').html('')

                validaAssinturas()
                
                $.get("/cadastro/assina-honorarios/"+$idDocumento, function( data ) {});
                
            }
            if (event.data === "wrong-data") {
                alert('DADOS ERRADOS');
            }
        }, false);

    }
    
    function assinaProcuracao($idDocumento, $email, $nome) {

        var key                     = $idDocumento;
        var signer_disable_preview  = "0";
        var signer_email            = $email;
        var signer_display_name     = $nome; //Opcional
        var signer_documentation    = ""; //Opcional
        var signer_birthday         = ""; //Opcional
        var signer_key_signer       = ""; //Opcional
        
        var host            = urlD4Sign+"/embed/viewblob";
        var container       = "signature-div";    
        var width           = '100%';
        var height          =  '100%';
        //----------FIM DAS VARIAVEIS----------//
        
        var is_safari = navigator.userAgent.indexOf('Safari') > -1;
        var is_chrome = navigator.userAgent.indexOf('Chrome') > -1;
        if ((is_chrome) && (is_safari)) {is_safari = false;}  
        if (is_safari) {
            if (!document.cookie.match(/^(.*;)?\s*fixed\s*=\s*[^;]+(.*)?$/)) {
                document.cookie = 'fixed=fixed; expires=Tue, 19 Jan 2038 03:14:07 UTC; path=/';
                var url = document.URL;
                var str = window.location.search;
                var param = str.replace("?", "");
                if (url.indexOf("?")>-1){
                    url = url.substr(0,url.indexOf("?"));
                }
                window.location.replace(urlD4Sign+"/embed/safari_fix?param="+param+'&r='+url);
            }
        }
        let iframe = document.createElement("iframe");
        if (signer_key_signer === ''){
            iframe.setAttribute("src", host+'/'+key+'?email='+signer_email+'&display_name='+signer_display_name+'&documentation='+signer_documentation+'&birthday='+signer_birthday+'&disable_preview='+signer_disable_preview);
        }else{
            iframe.setAttribute("src", host+'/'+key+'?email='+signer_email+'&display_name='+signer_display_name+'&documentation='+signer_documentation+'&birthday='+signer_birthday+'&disable_preview='+signer_disable_preview+'&key_signer='+signer_key_signer);
        }
        iframe.setAttribute("id", 'd4signIframe');
        iframe.setAttribute("width", width);
        iframe.setAttribute("height", height);
    
        iframe.style.border = 0;
        iframe.setAttribute("allow", 'geolocation');
        var cont = document.getElementById(container);
        cont.appendChild(iframe);
        window.addEventListener("message", (event) => {
            if (event.data === "signed") {

                let totalAss = parseInt($('#countAssinaturas').val())
                totalAss = totalAss + 1
                $('#countAssinaturas').val(totalAss)
                $('#btnOpenModalAssinaturaProcuracao').fadeOut()
                $('#procuracaoAssinadaAlert').fadeIn()
                $('.modalAssinatura').fadeOut()
                $('#tituloModal').html('')
                $('#signature-div').html('')

                validaAssinturas()

                $.get("/cadastro/assina-procuracao/"+$idDocumento, function( data ) {});
                
            }
            if (event.data === "wrong-data") {
                alert('DADOS ERRADOS');
            }
        }, false);

    }

    function validaAssinturas(){

        let totalAssinaturas = $('#countAssinaturas').val()
        if(totalAssinaturas >= 2) {
            $('#btnConcluir').fadeIn();
        }

    }

}