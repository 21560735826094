import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

// import 'owl.carousel';
let owl_carousel = require('owl.carousel');
window.fn = owl_carousel;

var vitrineSite = $('.vitrine');
if(vitrineSite.length > 0) {
    vitrineSite.find(".carousel").owlCarousel({
        loop: true,
        nav: false,
        dots: true,
        items: 1,
        autoplay:true,
        autoplayTimeout:5000
    });
}

$('.owl-carousel').each(function() {
    //Find each set of dots in this carousel
  $(this).find('.owl-dot').each(function(index) {
    //Add one to index so it starts from 1
    $(this).attr('aria-label', index + 1);
  });
});