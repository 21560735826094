import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

const ajudas = $('.ajuda');
if(ajudas.length > 0) {
    
    $('#ajuda .card-header').on('click', function(){
        const idElement = $(this).data('show');
        // console.log(idElement);
        $('.collapseContent').removeClass('show');
        $('#'+idElement).addClass('show');
        let hash = '#'+idElement;
        $('html, body').animate({
            scrollTop: $(hash).offset().top - 230
        }, 100);
    });
    
}