import $ from "jquery";
import 'jquery-mask-plugin';

$('.telefoneMask').mask("(00)00000-0000");
$('.cpfMask').mask("000.000.000-00");
$('.cepMask').mask("00000-000");

//Valida E-mail
export function IsEmail(email, required = undefined) {
    if (required == undefined) { //se não for especificado, assume que é obrigatório
        required=true;
    }
    return(/^\w+([.-]?\w+)@\w+([.-]?\w+)(.\w{1,3})+$/.test(email));//retorna true se a regra for obdecida

}

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    }
});

$.fn.serializeFormJSON = function () {
    const o = {};
    const a = this.serializeArray();
    $.each(a, function () {
        if (o[this.name]) {
            if (!o[this.name].push) {
                o[this.name] = [o[this.name]];
            }
            o[this.name].push(this.value || "");
        } else {
            o[this.name] = this.value || "";
        }
    });
    return o;
};

$('.scrollToForm').on('click', function(){
    $('html, body').animate({
        scrollTop: $('#contato').offset().top
    }, 700);
});