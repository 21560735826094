import jQuery from "jquery";
window.$ = window.jQuery = jQuery;

const headerSite = $('header');

export function menu(){
    const menuResponsivo = document.querySelectorAll('.linkMenu');
    if(headerSite.length > 0) {
        menuResponsivo.forEach(element => {
            element.addEventListener("click", function(){
                console.log(element);
            }, false);
        });
    }


}

export function menuMobile(){
    const btnMenuResponsivo = document.getElementById('menuMobile');
    const menu = document.getElementById('menu');
    if(headerSite.length > 0) {
        btnMenuResponsivo.addEventListener("click", function(){
            this.classList.toggle("active");
            menu.classList.toggle("active");
        }, false);
    }
}